import React from "react";
import { Button } from "antd";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Charts from "./component/charts";

import "./index.less";

const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const NoData = Loader.loadBaseComponent("NoData");
const Loading = Loader.Loading;
const IconFont = Loader.loadBaseComponent("IconFont");
// const ForeignCard = Loader.loadBusinessComponent('Card', 'ForeignCard');
const RefreshButton = Loader.loadBaseComponent("RefreshButton");
const RealAlarmList = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "RealAlarmList"
);

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("tab")
@observer
class EventRealNotify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortType: "1",
      GostAlarmList: [],
      show: false,
      infinitKey: Math.random(),
      Loading: true,
      circleList: { effectiveNum: 0, ineffectiveNum: 0, unHandledNum: 0 },
      chartList: [],
      showSpin: false,
      showLoading: true
    };
    this.initData();
    this.requestData();
    this.listRef = React.createRef();
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.alarm, this.handleAlarmBody);
    SocketEmitter.off(
      SocketEmitter.eventName.resolveAlarm,
      this.handleDoneAlarm
    );
  }

  initData() {
    SocketEmitter.on(SocketEmitter.eventName.alarm, this.handleAlarmBody);
    SocketEmitter.on(
      SocketEmitter.eventName.resolveAlarm,
      this.handleDoneAlarm
    );
  }

  handleAlarmBody = result => {
    let { GostAlarmList } = this.state;

    if (result.alarmType === "3" && !GostAlarmList.find(v => v.id === result.id)) {
      this.setState(
        {
          GostAlarmList: [result].concat(GostAlarmList)
        },
        () => {
          this.listRef.current &&
            this.listRef.current.infinitRef.current.forceUpdateGrid();
        }
      );
    }
  };

  /**监听滤除处理后的警情 */
  handleDoneAlarm = result => {
    const { GostAlarmList } = this.state;
    const index = GostAlarmList.findIndex(v => v.id === result.id);
    if (index > -1) {
      GostAlarmList.splice(index, 1);
      this.setState(
        {
          GostAlarmList
        },
        () => {
          this.listRef.current &&
            this.listRef.current.infinitRef.current.forceUpdateGrid();
        }
      );
    }
  };

  /**跳转详情页面 */
  handleJumPage = id => {
    const { tab, location } = this.props;
    const options = {
      offset: 0,
      limit: 24,
      alarmOperationType: 2,
      sortType: 1,
      logTypes: "3"
    };
    const moduleName = "eventDetail";
    const data = {
      id,
      libType: 3,
      searchData: options,
      isRealAlarm: true
    };
    tab.openDetailPage({
      moduleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add('parameter', {
          id: data.id.toString(),
          libType: data.libType,
          isRealAlarm: true,
          searchData: options
        })
      }
    });
  };

  /**请求列表数据 */
  requestData() {
    const options = {
      offset: 0,
      limit: 24,
      alarmOperationType: 2,
      sortType: 1,
      alarmTypes: ["3"],
      includeDeletedTasks: false
    };
    Service.alarmResult.queryAlarmResults(options).then(res => {
      this.setState({
        showLoading: false,
        Loading: false,
        GostAlarmList: res.data.list
      });
    });
  }
  /**刷新 */
  freshen = () => {
    this.setState({
      showSpin: true
    });
    this.requestData(true, 1);
    setTimeout(() => {
      this.setState({
        showSpin: false
      });
    }, 1500);
  };

  handleJumpMore = () => {
    const { location, tab } = this.props;
    const moduleName = "eventHistoryNotify";
    tab.goPage({ moduleName, location });
  };

  render() {
    const { GostAlarmList, showLoading, infinitKey } = this.state;
    return (
      <div className="event-real-notify">
        <div className="event-real-total">
          <div className="out-scroll-div">
            <Charts />
          </div>
        </div>
        <div className="event-real-container">
          <div className="alarm-top-tab">
            <div className="alarm-top-left" style={{ fontSize: "16px" }}>
              <IconFont type={"icon-S_Bar_PeopleAll"} theme="outlined" />
              <span style={{ paddingLeft: "2px", fontSize: "15px" }}>
              人员入侵
              </span>
            </div>
            <div className="alarm-just-button">
              <RefreshButton onClick={this.freshen} loading={showLoading} />
              <AuthComponent actionName="eventHistoryNotify">
                <Button className="more" onClick={this.handleJumpMore}>
                  查看更多
                </Button>
              </AuthComponent>
            </div>
          </div>
          <div className="alart_list_item">
            {GostAlarmList.length > 0 ? (
              <div className="alarm-tabs-tabscreate" >
                <RealAlarmList
                  ref={this.listRef}
                  key="eventHistoryNotify"
                  cardType="ForeignCard"
                  list={GostAlarmList}
                  itemWidth={174}
                  itemHeight={300}
                  pdWidth={120}
                  infinitKey={infinitKey}
                  handlePageJump={this.handleJumPage}
                />
              </div>
            ) :( showLoading ? (
              <Loading loading={showLoading} />
            ) : (
              <NoData imgType={2} title={"告警数据"} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default EventRealNotify;
